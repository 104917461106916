import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './theme';

import Home from './components/Home';
import MemeGallery from './components/MemeGallery';

function App() {
  return (
    <>
      <ThemeProvider theme={theme} >
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/meme" element={<MemeGallery />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;