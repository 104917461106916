import React, { useState, useEffect, useRef } from 'react';
import { ethers } from 'ethers';
import { Box, Typography, Grid, Container, Button } from '@mui/material';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

import CountDownTimer from './CountDownTimer';

import wavaxAbi from '../abi/wavaxAbi.json';
import usdcAbi from '../abi/usdcAbi.json';
import lpAbi from '../abi/lpAbi.json';

// Social media icons
import { Telegram } from '@mui/icons-material';
import TwitterIcon from './icons/Twitter';
import DexScreenerIcon from './icons/DexScreener';
import AvaxIcon from './icons/Avax';
import { Link } from 'react-router-dom';
import TestimonialSlider from './Testimonials';


// Styled components
const HeaderBar = styled.div`
  position: absolute;
  width: 100%;
  background-color: rgba(255, 255, 255, 0); 
  color: #000;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  @media all and (max-width: 599px){
    flex-direction: column;
    height: 120px;
  }
`;
const LogoLink = styled(Link)`
  display: flex;
  flex-align: center;
  align-items: center;
`
const LogoIcon = styled.img`
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto 0;
  border: 4px solid #FFF;
  box-shadow: 0 2px 6px #9d820060;
  border-radius: 40px;
    transition: transform 200ms ease, scale 200ms ease;
  &:hover{
    transform: rotate(-10deg);
    scale: 1.3;
  }
`;
const LogoText = styled.img`
  display: block;
  width: 100px;
  height: 44px;
  margin-left: 10px;
  transition: transform 200ms ease, scale 200ms ease;
  &:hover{
    transform: rotate(-10deg);
    scale: 1.3;
  }
`
const HeaderLink = styled(Link)`
  color: #000;
  text-decoration: none;
  padding: 0 15px;
  zIndex: 10;
  &:hover {
    text-decoration: underline;
  }
  @media all and (max-width: 399px){
    padding: 0 8px;
    font-size: 16px;
  }
`;
const MemesHeaderLink = styled(HeaderLink)`
  @media all and (max-width: 599px){
    display: none;
  }
`
const FullScreenContainer = styled.div`
  min-height: 100vh;
  padding: 60px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const rotateBackground = keyframes`
  from {
    transform: rotate(0deg) translate3d(0, 0, 0); // Use translate3d for better performance
    background-position: center;
  }
  to {
    transform: rotate(-360deg) translate3d(0, 0, 0);
    background-position: center;
  }
`;
const HomeSection = styled(FullScreenContainer)`
  position: relative;
  overflow: hidden;
`;
const FlatBackground = styled.div`
  position: absolute;
  background: #FFCC00;
  width: 100%;
  height: 100%;
  z-index: -3;
`;
const DotBackground = styled.div`
  position: absolute; // Change to relative to correctly position the pseudo-element
  width: 100%;
  height: 100%;
  z-index: -2;
  
  // Using ::before pseudo-element to overlay a gradient on the image
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to top, rgba(255,204,0,0.5), rgba(255,204,0,1)); // Adjust the colors and direction as needed
    z-index: -1;
  }
  
  // Applying the actual background image to the DotBackground
  background-image: url('/images/dots.png');
  background-size: 100px auto;
  background-repeat: repeat;
`;
const RotatingBackground = styled.div`
  position: absolute;
  width: 180vw; // Ensure width is enough to cover the screen
  height: 180vw; // Keep height equal to width to maintain the square aspect
  top: -40%;
  left: -40%;
  background-image: url('/images/starburst.png'); 
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: ${rotateBackground} 100s linear infinite;
  transform-origin: center center;
  z-index: -1;
  @media all and (max-width: 999px){
    width: 240vw;
    height: 240vw;
    left: -70%;
    top: -8%;
  }
`;
const BuyLink = styled(Link)`
  display: block;
  margin: 20px auto 20px;
  width: 220px;
  transition: scale 300ms ease, transform 300ms ease; 
  &:hover{
    scale: 1.5;
    transform: rotate(-10deg);
  }
`;
const SecondSection = styled(FullScreenContainer)`
  position: relative;
  flex-direction: column;  
  background-color: #4C7DCC;
  // background-image: url('images/background-2.jpg');
  background-repeat:no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 60px 12px 300px;
`;
const StatsSection = styled(FullScreenContainer)`
  position: relative;
  flex-direction: column;  
  background-color: #01022e;
`
const Logo = styled.img`
  display: block;
  width: 240px;
  height: 240px;
  margin: 0 auto 40px;
  border: 10px solid #fff;
  border-radius: 120px;
  box-shadow: 0 4px 18px #9d820040;
  transition: transform 200ms ease, scale 200ms ease;
  &:hover{
    transform: rotate(10deg);
    scale: 1.2;
  }
  @media all and (max-width: 599px){
    width: 120px;
    height: 120px;
    margin: 80px auto 20px;
    border: 8px solid #fff;
  }
`;
const StyledButton = styled(Button)`
&&{
  display: block;
  text-decoration: none;
  font-size: 24px;
  padding: 4px 20px;
  border: 3px solid #c19203;
  color: white;
  background: #02010f !important;
  margin: 30px auto 10px;
  transition: scale 300ms, transform 300ms;
  &:hover{
    background: #02010f !important;
    scale: 1.1;
    transform: rotate(-8deg);
  }
}
`
const SocialMediaSection = styled(FullScreenContainer)`
  display: flex;
  flex-direction: column;
  background-color: #62c267;
  background-image: url('images/background-3.png');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: auto 700px;
  @media all and (max-width: 1199px){
    background-size: 100%;
  }
  @media all and (max-width: 799px){
    background-size: 150%;
  }
`;

const SocialIconContainer = styled.div`
  color: white;
  & > svg {
    margin: 0 10px;
    font-size: 40px;
  }
`;
const StyledLink = styled(Link)`
&& {
  &:hover{
    > svg{
      scale: 1.2;
    }
  }
}
`

const Tweets = styled.img`
  position: relative;
  max-width: 100%;
`;


const Bruh = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 420px;
  z-index: 2;
  @media all and (max-width: 999px){
    width: 320px;
    max-width: none;
  }
  @media all and (max-width: 599px){
    width: 220px;
    max-width: none;
  }
  @media all and (max-width: 499px){
    margin-left: -10%;
  }
`;

// Keyframes for the bounce-in effect from the upper right
const bounceIn = keyframes`
  0% {
    transform: translate(100%, -100%) scale(0);
    opacity: 0;
  }
  50% {
    transform: translate(0, 0) scale(1.1);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
`;

const bounceInGlasses = keyframes`
  0% {
    transform: translate(0, -300px) scale(1);
    opacity: 0;
  }
  1% {
    transform: translate(0, -300px) scale(1);
    opacity: 1;
  }
  50% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
`;

const BruhGlasses = styled.img`
  position: absolute;
  opacity: 0;
  animation: ${bounceInGlasses} 4s ease forwards;
  animation-delay: 2s;
  z-index: 5;
  width: 200px;
  bottom: 297px;
  left: Calc(50% + 11px);
  @media (max-width: 999px) {
    width: 140px;
    bottom: 226px;
    left: Calc(50% + 12px);
  }
  @media (max-width: 599px) {
    width: 110px;
    bottom: 155px;
    left: Calc(50% + 0px);
  }
  @media (max-width: 499px) {
    width: 110px;
    bottom: 155px;
    left: Calc(40% + 0px);
  }
`;

const BruhSaying = styled.img`
  position: absolute;
  opacity: 0;
  animation: ${bounceIn} 1s ease forwards;
  animation-delay: 2s;
  z-index: 5;
  width: 180px;
  bottom: 200px;
  left: Calc(50% + 240px);
  @media (max-width: 999px) {
    width: 100px;
    bottom: 160px;
    left: Calc(50% + 170px);
  }
  @media (max-width: 599px) {
    width: 80px;
    bottom: 100px;
    left: Calc(50% + 120px);
  }
  @media (max-width: 399px) {
    width: 80px;
    bottom: 100px;
    left: Calc(40% + 120px);
  }
`;


interface Pair {
  chainId: string;
  dexId: string;
  url: string;
  pairAddress: string;
  baseToken: {
    address: string;
    name: string;
    symbol: string;
  };
  quoteToken: {
    symbol: string;
  };
  priceNative: string;
  priceUsd?: string;
  fdv?: number;
}

interface TokensResponse {
  schemaVersion: string;
  pairs: Pair[] | null;
}

interface PriceTickerProps {
  tokenAddress: string;
}

const PriceTicker: React.FC<PriceTickerProps> = ({ tokenAddress }) => {
  const [data, setData] = useState<{ priceUsd: string; fdv: number | 'Loading...' | 'N/A' | 'Error' }>({
    priceUsd: 'Loading...',
    fdv: 'Loading...',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://api.dexscreener.com/latest/dex/tokens/${tokenAddress}`);
        const jsonData: TokensResponse = await response.json();
        if (jsonData.pairs && jsonData.pairs.length > 0) {
          const { priceUsd, fdv } = jsonData.pairs[0];
          setData({
            priceUsd: priceUsd || 'N/A',
            // If fdv is not undefined, convert it to a number, otherwise, set to 'N/A'
            fdv: fdv !== undefined ? Number(fdv) : 'N/A',
          });
        }
      } catch (error) {
        console.error("Failed to fetch token data:", error);
        setData({ priceUsd: 'Error', fdv: 'Error' });
      }
    };

    fetchData();
  }, [tokenAddress]);

  return (
    <div>
      <Typography style={{ fontSize: '28px', lineHeight: '38px', marginBottom: '20px' }}>Current Price: ${data.priceUsd}</Typography>
      {/* Check if fdv is a number before formatting, otherwise display as is */}
      <Typography style={{ fontSize: '28px', lineHeight: '38px', marginBottom: '10px' }}>Market Cap: ${typeof data.fdv === 'number' ? data.fdv.toLocaleString() : data.fdv}</Typography>
    </div>
  );
};

const MemeWebsite: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        rootMargin: '0px',
        threshold: 0.1, // Adjust based on when you want the animation to trigger
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [sectionRef]);
  
  return (
    <Box>
      <HeaderBar>
        <LogoLink to ="/"><LogoIcon src="/images/token-icon-120.png" alt="" /> <LogoText src="/images/bruh-logo.png" /></LogoLink>
        <Box>
          <MemesHeaderLink to="https://twitter.com/avaxbruh" target="_blank" rel="noopener">Twitter</MemesHeaderLink>
          <MemesHeaderLink to="https://t.me/AvaxBruh" target="_blank" rel="noopener">Telegram</MemesHeaderLink>
          <MemesHeaderLink to="https://dexscreener.com/avalanche/0xcba7547a075bc50843c29e59554c807726ef7268" target="_blank" rel="noopener">DEXScreener</MemesHeaderLink>
          <HeaderLink to="/meme">Memes</HeaderLink>
          <HeaderLink to="https://miner.ogbruh.xyz" target="_blank" rel="noopener">Bruh Miner</HeaderLink>
        </Box>
      </HeaderBar>
      <HomeSection>
        <FlatBackground />
        <RotatingBackground />
        <DotBackground />
        <Box style={{ zIndex: 3 }}>
          <Logo src="/images/token-icon-512.png" alt="" />
          <Typography variant="h1" textAlign="center" color="white" fontSize="42px" lineHeight="70px" style={{ 
            display: 'flex',
            justifyContent: 'center',
            color: '#fff',
            textShadow: '-2px -2px 0 black, -2px 0 0 black, -2px 2px 0 black, 0 2px 0 black, 2px 2px 0 black, 2px 0 0 black, 2px -2px 0 black, 0 -2px 0 black,  4px 4px 0 black',
          }}>
            OG&nbsp;<span style={{ color: '#ffd505' }} >bruh</span>&nbsp;coin
          </Typography>
          <Typography variant="h3" textAlign="center" color="white" fontSize="28px" lineHeight="38px" fontWeight="400"  mb="30px" style={{ 
            display: 'flex',
            justifyContent: 'center',
            color: '#ffd505',
            textShadow: '-2px -2px 0 black, -2px 0 0 black, -2px 2px 0 black, 0 2px 0 black, 2px 2px 0 black, 2px 0 0 black, 2px -2px 0 black, 0 -2px 0 black,  3px 3px 0 black',
            }}>
              bring back the bruh
            </Typography>
            <Typography variant="h3" textAlign="center" color="white" fontSize="22px" lineHeight="32px" fontWeight="400"  mb="30px" style={{ 
              display: 'flex',
              justifyContent: 'center',
              color: '#FFF',
              textShadow: '-2px -2px 0 black, -2px 0 0 black, -2px 2px 0 black, 0 2px 0 black, 2px 2px 0 black, 2px 0 0 black, 2px -2px 0 black, 0 -2px 0 black,  3px 3px 0 black',
              maxWidth: '100%',
              wordBreak: 'break-word',
              }}>
              0xa5Dbc83ce195b93602F1907CeBB8A2eC99b016C3
            </Typography>
            <BuyLink to="https://traderjoexyz.com/avalanche/trade?outputCurrency=0xa5Dbc83ce195b93602F1907CeBB8A2eC99b016C3" target="_blank" rel="noopener">
              <img src="/images/buy-button.png" width="220px" height="auto" />
            </BuyLink>
            <BuyLink to="/meme">
              <img src="/images/browse-memes.png" width="220px" height="auto" />
            </BuyLink>
        </Box>
      </HomeSection>
      <SecondSection>
        <Typography variant="h3" textAlign="center" color="white" fontSize="44px" lineHeight="48px" fontWeight="400"  mb="30px" style={{ 
            color: '#FFF',
            marginBottom: '70px',
            textShadow: '-2px -2px 0 black, -2px 0 0 black, -2px 2px 0 black, 0 2px 0 black, 2px 2px 0 black, 2px 0 0 black, 2px -2px 0 black, 0 -2px 0 black,  3px 3px 0 black',
            }}>
              everybody loves <span style={{ color: '#fcc82a' }}>$bruh</span>
            </Typography>
        <Tweets src="/images/tweets.png" alt="" />

        <Box style={{ zIndex: 3 }}>
          <Bruh src="/images/bruh-green.png" alt="" />
          <BruhGlasses src="/images/meme-glasses.png" alt="glasses" />
          <BruhSaying src="/images/saying-bruh.png" alt="" />
        </Box>
      </SecondSection>
      <SocialMediaSection>
        <Typography variant="h4" textAlign="center" color="white" fontSize="22px" lineHeight="26px" fontWeight="400" style={{ 
          display: 'flex',
          justifyContent: 'center',
          textShadow: '2px 2px 10px rgba(0,0,0,0.06', 
          }}>
          <PriceTicker tokenAddress="0xa5Dbc83ce195b93602F1907CeBB8A2eC99b016C3" />
        </Typography>
        <Typography fontSize="16px" color="white" style={{
          margin: '0 0 20px',
          textShadow: '2px 2px 8px rgba(0,0,0,0.06)',
          textAlign: 'center'
          }}>
            $BRUH is the only bruh community coin that has been completely fair launched without a presale or team allocation.<br /> 90% of the supply was added directly to the LP. A 0.1% airdrop was distributed to V2 BRUH NFT holders for the culture (total 10%).<br /> The initial LP tokens have been completely burned. 
          </Typography>
        <Box style={{ display: 'flex', margin: '20px auto 40px' }}>
          <Link to="https://t.me/AvaxBruh" target="_blank" rel="noopener"><Telegram style={{ color: 'white', fontSize: '70px', margin: '-5px 0 5px' }} /></Link>
          <Link to="https://twitter.com/avaxbruh" target="_blank" rel="noopener" style={{ margin: '0 40px'}} ><TwitterIcon color="white" width="60px" /></Link>
          <Link to="https://dexscreener.com/avalanche/0xcba7547a075bc50843c29e59554c807726ef7268" target="_blank" rel="noopener"><DexScreenerIcon color="white" width="50px" /></Link>
        </Box>
        <Box style={{ display: 'block', width: '700px', maxWidth: '92%',  margin: '0 auto 40px', border: '2px solid #555', padding: '15px 15px', color: 'white', textAlign: 'center', fontFamily: 'sans-serif', fontSize: '16px', textShadow: '2px 2px 8px rgba(0,0,0,0.1)'  }}>
          <span style={{ color: '#444',  fontWeight: 600, textShadow: 'none'  }}>$BRUH</span> is a meme coin with no intrinsic value or expectation of financial return. <br />There is no formal team or roadmap. The coin is for entertainment purposes only.
         </Box>
         <Typography fontSize="14px" color="white" style={{
          margin: '0 0 250px',
          textShadow: '2px 2px 8px rgba(0,0,0,0.06)', 
          }}>
            © Copyright 2024: OG Bruh coin
          </Typography>
      </SocialMediaSection>
    </Box>
  );
};

export default MemeWebsite;