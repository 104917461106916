import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, Typography, Button, TextField, Card, CardMedia, CardContent, CardActions, IconButton, MenuItem, Select, CircularProgress } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';

import { Meme, memeData } from './MemeData';

const Body = styled(Box)`
  display: block;
  width: 100%;
  min-height: 110vh;
  background-color: #FFCC00;
`
const BodyContent = styled(Box)`
  margin: 0 auto;
  max-width: 2800px;
`
const BackButton = styled(Link)`
  display: flex;
  width: 150px;
  height: 60px;
  padding: 10px 0;
  border-radius: 12px;
  text-decoration: none;
  cursor: pointer;
  &:hover{
    background: rgba(0,0,0,0.1);
  }
`
const SelectOptions = styled(Box)`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  @media all and (max-width: 599px){
    flex-direction: column; 
  }
`
const ContentArea = styled(Box)`
  background-color: #FFFFFF; /* White background */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
`;

const DownloadButton = styled(Button)`
&&{
  background: rgb(225,225,225);
  color: white;
  width: 20px;
  &:hover{
    background: #666;
  }
}
`

function useBatchLoad<T>(data: T[], batchSize: number): [T[], () => void] {
  const [index, setIndex] = useState(0);
  const [batchedData, setBatchedData] = useState<T[]>([]);

  useEffect(() => {
    setBatchedData(data.slice(0, index + batchSize));
  }, [data, index, batchSize]);

  const loadMore = () => {
    if (index + batchSize < data.length) {
      setIndex(index + batchSize);
    }
  };

  return [batchedData, loadMore];
}


const MemeGallery: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [typeFilter, setTypeFilter] = useState('image');
  const [eraFilter, setEraFilter] = useState('');
  const [filteredMemes, setFilteredMemes] = useState<Meme[]>(memeData);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      const filtered = memeData.filter(meme =>
        (meme.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
         meme.keywords.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (typeFilter ? meme.type === typeFilter : true) &&
        (eraFilter ? meme.era === eraFilter : true)
      );
      setFilteredMemes(filtered);
      setTimeout(() => setIsLoading(false), 500);
    }, 1000); 
  }, [searchTerm, typeFilter, eraFilter]);

  const handleDownload = (url: string, filename: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };


  return (
    <Body sx={{ flexGrow: 1, p: 3 }}>
      <BodyContent>
        <Box style={{ display: 'flex'  }}>
          <BackButton
            to="/"
            style={{ display: 'flex', marginBottom: '20px' }}
            aria-label="Back"
            >
            <ArrowBackIcon sx={{ width: '50px', fontSize: '40px', marginRight: '5px', color: '#000' }} />
            <Typography variant="h3" textAlign="center" color="white" fontSize="28px" lineHeight="38px" fontWeight="400"  mb="30px" style={{ 
              display: 'flex',
              justifyContent: 'center',
              color: '#FFF',
              textShadow: '-2px -2px 0 black, -2px 0 1px black, -2px 2px 0 black, 0 2px 0 black, 2px 2px 0 black, 2px 0 0 black, 2px -2px 0 black, 0 -2px 0 black,  3px 3px 0 black',
              }}> Back </Typography>
          </BackButton>
        </Box>
        <ContentArea>
          <Typography variant="h4" mb={2}>
            Meme Repository
          </Typography>
          <TextField
            fullWidth
            label="Search Memes"
            variant="outlined"
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ mb: '15px' }}
          />
          <SelectOptions>
            <Select
              value={typeFilter}
              onChange={(e) => setTypeFilter(e.target.value)}
              displayEmpty
              fullWidth
              inputProps={{ 'aria-label': 'Filter by Tag' }}
            >
              <MenuItem value="">
                <em>Image Type (All)</em>
              </MenuItem>
              <MenuItem value="image">Image</MenuItem>
              <MenuItem value="gif">GIF</MenuItem>
            </Select>
            <Select
              value={eraFilter}
              onChange={(e) => setEraFilter(e.target.value)}
              displayEmpty
              fullWidth
              inputProps={{ 'aria-label': 'Filter by Type' }}
            >
              <MenuItem value="">
                <em>Select Era (All)</em>
              </MenuItem>
              <MenuItem value="new">New Era V1-Theme Memes</MenuItem>
              <MenuItem value="old">V2 Memes (credit: Barbo mostly)</MenuItem>
            </Select>
          </SelectOptions>
          {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>
            <CircularProgress size={80} style={{ color: '#999' }} />
          </Box>
        ) : (
          <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 6  }} spacing={2} style={{ width: 'Calc(100% + 12px)' }} >
          {filteredMemes.map((meme, index) => (
            <Card key={meme.id} sx={{ position: 'relative', maxWidth: '520px', mb: 2, borderRadius: '12px' }}>
              <Box style={{ minHeight: '200px' }}>
                  <img
                    src={meme.url}
                    alt={meme.name}
                    style={{ 
                      width: '100%', 
                      height: 'auto',
                      display: 'block',
                      position: 'relative',
                      top: 0,
                      left: 0,
                    }}
                    loading="lazy"
                  />
              </Box>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {meme.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {meme.keywords.split(', ').join(', ')}
                </Typography>
              </CardContent>
              <CardActions>
                <DownloadButton onClick={() => handleDownload(meme.url, meme.name)} style={{ marginRight: '10px' }}>
                  <DownloadIcon />
                </DownloadButton>
              </CardActions>
            </Card>
          ))}
          </Masonry>
        )}
        </ContentArea>
      </BodyContent>
    </Body>
  );
};

export default MemeGallery;