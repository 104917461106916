import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#01022E', // Example primary color
    },
    secondary: {
      main: '#191919', // Example secondary color
    },
  },
  typography: {
    fontFamily: [
      'Chelsea Market',
      'sans-serif'
    ].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '38px',
      lineHeight: '52px',
      margin: '0 0 15px',
    },
    h2: {
      fontWeight: 700,
      fontSize: '34px',
      lineHeight: '44px',
      margin: '0 0 15px',
    },
    h3: {
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '36px',
      margin: '0 0 15px',
    },
    h4: {
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
      margin: '0 0 15px',
    },
    h5: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      margin: '0 0 15px',
    },
    h6: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
      margin: '0 0 15px',
    },
    body1: {
      fontFamily: 'Chelsea Market',
      fontSize: '20px',
      lineHeight: '30px',
    },
    body2: {
      fontFamily: 'Chelsea Market',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          fontFamily: 'Chelsea Market, sans-serif',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
        },
        code: {
          fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
        },
      },
    },
    MuiButton: {
      
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          paddingRight: '8px !important',
        }
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          maxWidth: '240px',
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none !important'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.02)'
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          
          padding: '0px 24px', // Reduced vertical padding
          '&.Mui-expanded': {
            minHeight: 48,
          },
          '& .MuiAccordionSummary-content': {
            margin: '0'
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0', // Maintain vertical margin when expanded
          }
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.02)',
          transition: 'background-color 0.3s', // Smooth transition for background color
        }
      }
    }
  }
});

export default theme;