// Defining the Meme type
export interface Meme {
  id: number;
  name: string;
  keywords: string;
  type: 'image' | 'gif' | 'video';
  era: 'new' | 'old';
  url: string;
}

// Defining the Meme data
export const memeData: Meme[] = [


  
  {
    id: 1009,
    name: 'Dinner with Candles',
    keywords: 'dinner, thot, chart, candles, bruh',
    type: 'image',
    era: 'new',
    url: '/images/memes/dinner-with-candles.jpg',
  },
  {
    id: 1008,
    name: 'How to be Bruh',
    keywords: 'relax, comfy, calm, bruh',
    type: 'image',
    era: 'new',
    url: '/images/memes/how-to-be-bruh.jpg',
  },
  {
    id: 1007,
    name: 'Candle Dealer Bruh',
    keywords: 'green, candles, pepe, backpack, coat, bruh',
    type: 'image',
    era: 'new',
    url: '/images/memes/hidden-candles.jpg',
  },
  {
    id: 1006,
    name: 'The Cult of Bruh',
    keywords: 'cult, bruh',
    type: 'gif',
    era: 'new',
    url: '/images/memes/cult-of-bruh.gif',
  },
  {
    id: 1005,
    name: 'Hide the Pain Bruh Template',
    keywords: 'harold, hide the pain, bruh',
    type: 'image',
    era: 'new',
    url: '/images/memes/hide-the-pain-template.jpg',
  },
  {
    id: 1004,
    name: 'Both Bruh Template',
    keywords: 'both, double press, bruh',
    type: 'image',
    era: 'new',
    url: '/images/memes/double-press-template.jpg',
  },
  {
    id: 1003,
    name: 'Both Bruh',
    keywords: 'both, double press, bruh',
    type: 'image',
    era: 'new',
    url: '/images/memes/double-press-bruh.jpg',
  },
  {
    id: 1002,
    name: 'Salt Bruh',
    keywords: 'salt, salt bae, bae, tears, cry, bruh',
    type: 'image',
    era: 'new',
    url: '/images/memes/salt-bruh.jpg',
  },
  {
    id: 1001,
    name: 'Dad Bruh',
    keywords: 'dad, son, man, avax',
    type: 'image',
    era: 'new',
    url: '/images/memes/bruh-dad.jpg',
  },

  {
    id: 900,
    name: 'Higher Bruh',
    keywords: 'higher, creed, bruh',
    type: 'gif',
    era: 'new',
    url: '/images/memes/higher-bruh.gif',
  },



  // Old-style memes
  {
    id: 70,
    name: 'Let Him Cook Bruh',
    keywords: 'cook, let him cook, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/let-him-cook-bruh.jpg',
  },
  {
    id: 69,
    name: 'BruhfBI',
    keywords: 'bruhfbi, fbi, special agent, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruhfbi.jpg',
  },

  // Gap in order

  {
    id: 62,
    name: 'Bruhayc',
    keywords: 'bored bruh, bayc, bored ape bruh, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruhayc.jpg',
  },
  {
    id: 61,
    name: 'Bruhferias',
    keywords: 'bruhferias, comfy, smoking, cigarette, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-ferias.png',
  },
  {
    id: 60,
    name: 'Bruh Coming',
    keywords: 'coming, terminator, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-coming.jpg',
  },
  {
    id: 59,
    name: 'Help Mommy',
    keywords: 'help, mommy, nfts, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/help-mommy.jpg',
  },
  {
    id: 58,
    name: 'IDC Bruhd',
    keywords: 'i dont care, idc, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-idc.png',
  },
  {
    id: 57,
    name: 'Bruhd Ass',
    keywords: 'bad ass, gun, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruhd-ass.jpg',
  },
  {
    id: 56,
    name: 'Bruh Cabal',
    keywords: 'bruh cabal, fk knobs, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-dao.jpg',
  },
  {
    id: 55,
    name: 'Bitch WTF',
    keywords: 'bruh wtf, bitch wtf, what the, no, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bitch-wtf.jpg',
  },
  {
    id: 54,
    name: 'GM Bruh',
    keywords: 'gm, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/gm-bruh.jpg',
  },
  {
    id: 53,
    name: 'GM Pope Bruh',
    keywords: 'gm, pope, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/gm-pope-bruh.jpg',
  },
  {
    id: 52,
    name: 'Bruh Idea',
    keywords: 'idea, bruh decaprio, leonardo, decaprio, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-idea.jpg',
  },
  {
    id: 51,
    name: 'Bruhmp',
    keywords: 'trump, bruhmp, bye, thanks, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruhmp.png',
  },
  {
    id: 50,
    name: 'Bruhnis',
    keywords: 'justin, benis, bruhnis, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruhnis.jpg',
  },
  {
    id: 49,
    name: 'Kevin Bruh',
    keywords: 'kevin, kevin bruh, nft, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/kevin-bruh.jpg',
  },
  {
    id: 48,
    name: 'Bruh Bruh',
    keywords: 'bruh, disappointed bruh, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-bruh.png',
  },
  {
    id: 47,
    name: 'Bruh Explosion',
    keywords: 'explosion, walking away, walk, bruh',
    type: 'gif',
    era: 'old',
    url: '/images/memes/bruh-explosion.gif',
  },
  {
    id: 46,
    name: 'Bruh Nothing to See',
    keywords: 'nothing to see, bruh nothing, explosion, bruh',
    type: 'gif',
    era: 'old',
    url: '/images/memes/bruh-nothing-to-see.gif',
  },
  {
    id: 45,
    name: 'Island Bruhs',
    keywords: 'island, island boys, bruh',
    type: 'gif',
    era: 'old',
    url: '/images/memes/island-bruh.gif',
  },
  {
    id: 44,
    name: 'Bruh Skates',
    keywords: 'skates, bruh skates, bruh',
    type: 'gif',
    era: 'old',
    url: '/images/memes/bruh-skates.gif',
  },
  {
    id: 43,
    name: 'Bruh Skates 2',
    keywords: 'skates, bruh skates, bruh',
    type: 'gif',
    era: 'old',
    url: '/images/memes/bruh-skates-2.gif',
  },
  {
    id: 42,
    name: 'Taken Bruh',
    keywords: 'will find you, taken, taken bruh, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/taken-bruh.png',
  },
  {
    id: 41,
    name: 'Dead and Bruhried',
    keywords: 'dead, buried, bruhried, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/dead-bruhried.jpg',
  },
  {
    id: 40,
    name: 'Is Bruh In The Room?',
    keywords: 'is bruh in, room, disbelief, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/is-bruh-in-the-room.png',
  },
  {
    id: 39,
    name: 'Bruh Would',
    keywords: 'would, bruh would, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-would.jpg',
  },
  {
    id: 38,
    name: 'Buy Bruh',
    keywords: 'buy, bullish, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/buy-bruh.png',
  },
  {
    id: 37,
    name: 'Bruhtoshi',
    keywords: 'bruhtoshi, satoshi, nakamoto, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruhtoshi.jpg',
  },
  {
    id: 36,
    name: 'Bruh Shake',
    keywords: 'shake, handshake, agree, yes, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-shake.jpg',
  },
  {
    id: 35,
    name: 'Drake Template',
    keywords: 'drake, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/drake-template.jpg',
  },
  {
    id: 34,
    name: 'Drake 2',
    keywords: 'drake, no, nah, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/drake-2.jpg',
  },
  {
    id: 33,
    name: 'Bruhnalisa',
    keywords: 'bruhnalisa, monalisa, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruhnalisa.jpg',
  },
  {
    id: 32,
    name: 'Bruhnalisa Exhibit',
    keywords: 'bruhnalisa, monalisa, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruhnalisa-exhibit.jpg',
  },
  {
    id: 31,
    name: 'Bruh Selfie',
    keywords: 'thicc, based, gym, chad, selfie, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-selfie.jpg',
  },
  {
    id: 30,
    name: 'Reasons to Bruh',
    keywords: 'reasons, reasons to live, live, comfy, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/reasons-to-bruh.png',
  },
  {
    id: 29,
    name: 'Bruh Meme Glasses',
    keywords: 'meme glasses, glasses, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-meme-glasses.png',
  },
  {
    id: 28,
    name: 'Bruh Around Bruh Bruh',
    keywords: 'fk around, fuck around, bruh around, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-around-bruh-bruh.png',
  },
  {
    id: 27,
    name: 'Rope Bruh',
    keywords: 'rope, what, no, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/rope-bruh.png',
  },
  {
    id: 26,
    name: 'Colonel Bruh',
    keywords: 'colonel, oh, okay, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/colonel-bruh.jpg',
  },
  {
    id: 25,
    name: 'Fk Yeh Winning Bruh',
    keywords: 'fk yeh, fuck, yeah, yes, winning, losing, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/fk-yeh.jpg',
  },
  {
    id: 24,
    name: 'Placing Bruh',
    keywords: 'fk yeh, fuck, yeah, yes, placing, winning, losing, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/placing.jpg',
  },
  {
    id: 23,
    name: 'Ferdy Bruh',
    keywords: 'ferdy, bane, smol, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/ferdy-bruh.jpg',
  },
  {
    id: 22,
    name: 'Pour One Out Bruh',
    keywords: 'pour one out, drink, RIP, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/pour-one-out-bruh.jpg',
  },
  {
    id: 21,
    name: 'Bruh Tea',
    keywords: 'tea, enemies, tears, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-tea.png',
  },
  {
    id: 20,
    name: 'Bruhspeagal',
    keywords: 'cigarette, hair, speagal, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-speagal.png',
  },
  {
    id: 19,
    name: 'Elon Bruh',
    keywords: 'elon, smoke, weed, what, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/elon-bruh.jpg',
  },
  {
    id: 18,
    name: 'Bruhvax',
    keywords: 'avax, squat, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruhvax.png',
  },
  {
    id: 17,
    name: 'Bruh Protect',
    keywords: 'protect, shied, drama, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-protect.png',
  },
  {
    id: 16,
    name: 'Bruh Piss',
    keywords: 'piss, ass, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-piss.jpg',
  },
  {
    id: 15,
    name: 'Bruhpup',
    keywords: 'pup, twerk, squat, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruhpup.png',
  },
  {
    id: 14,
    name: 'Bruh Slap Fud',
    keywords: 'slap, fud, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-slap-fud.jpg',
  },
  {
    id: 13,
    name: 'Bruh Slap Drama',
    keywords: 'slap, drama, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-slap-drama.jpg',
  },
  {
    id: 12,
    name: 'Bruh Joke',
    keywords: 'laugh, funny, joke, clown, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-joke.jpg',
  },
  {
    id: 11,
    name: 'Bruh Laugh',
    keywords: 'laugh, funny, braces, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-laugh.png',
  },
  {
    id: 10,
    name: 'Bruh DeCaprio',
    keywords: 'cheers, leonardo, decaprio, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-decaprio.png',
  },
  {
    id: 9,
    name: 'Bruhsss',
    keywords: 'what, surprise, intrigue, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruhsss.png',
  },
  {
    id: 8,
    name: 'Bruh Light Up',
    keywords: 'light up, smoke, cigar, cigarette, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-light-up.png',
  },
  {
    id: 7,
    name: 'Smart Bruh',
    keywords: 'smart, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/smart-bruh.png',
  },
  {
    id: 6,
    name: 'Ne Bruh',
    keywords: 'ne, no, cry, sad, upset, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/ne-bruh.jpg',
  },
  {
    id: 5,
    name: 'Ye Bruh',
    keywords: 'ye, yes, happy, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/ye-bruh.jpg',
  },
  {
    id: 4,
    name: 'Nice Bruh',
    keywords: 'nice, happy for you, okay, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/nice-bruh.png',
  },
  {
    id: 3,
    name: 'Bruh Blush',
    keywords: 'blush, shy, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-blush.png',
  },
  {
    id: 2,
    name: 'Bruh Cry',
    keywords: 'cry, crying, sad, upset, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-cry.png',
  },
  {
    id: 1,
    name: 'Ooh Bruh',
    keywords: 'ooh, surprise, intrigue, bruh',
    type: 'image',
    era: 'old',
    url: '/images/memes/bruh-ooh.png',
  },

];